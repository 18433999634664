import { useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { TimeValidationError } from "@mui/x-date-pickers";
import { utcToZonedTime } from "date-fns-tz";
import { isDefined } from "@clipboard-health/util-ts";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";

import { DefaultShiftTimes } from "@src/containers/facilityDashboard/ShiftCalendar/api";
import {
  convertShiftTimeToDateTime,
  normalizeShiftEndTime,
} from "@src/containers/facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { ShiftName } from "@src/appV2/ShiftBlock/types/shift";
import { ShiftInviteInfo } from "./ShiftInviteReview";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { differenceInHours, isSameDay, max } from "date-fns";
import { Text } from "@clipboard-health/ui-react";
import { getShiftTagColor } from "@src/components/shift";

export interface ShiftTypeTimeSelectionProps {
  shiftType: ShiftName;
  onChange?: (value: SelectedShiftInfo) => void;
  shiftTime: DefaultShiftTimes[keyof DefaultShiftTimes];
  selectedDate?: Date;
  shiftInfo: ShiftInviteInfo | undefined;
}

export interface SelectedShiftInfo {
  shiftType: ShiftName;
  start?: Date;
  end?: Date;
  isSelected: boolean;
}

export function ShiftTypeTimeSelection(props: ShiftTypeTimeSelectionProps) {
  const { shiftType, onChange, shiftTime, selectedDate, shiftInfo } = props;
  const { facilityTimeZone } = useShiftInviteContext();
  const { startTime, endTime } = isDefined(shiftInfo)
    ? { startTime: shiftInfo.start, endTime: shiftInfo.end }
    : convertShiftTimeToDateTime({ shiftTime, startDate: selectedDate });

  const [timeError, setTimeError] = useState<TimeValidationError | "invalidRange">();
  const isSelected = isDefined(shiftInfo);
  function onShiftInfoChange(params: Partial<SelectedShiftInfo>) {
    onChange?.({
      shiftType,
      start: startTime,
      end: endTime,
      isSelected,
      ...params,
    });
  }
  const duration = differenceInHours(endTime, startTime, { roundingMethod: "ceil" });
  const showTimeError = timeError === "minTime";
  const showDurationWarning = duration > 17;
  const showWarningMessage = showTimeError || showDurationWarning;
  const nowDateTime = utcToZonedTime(new Date(), facilityTimeZone);
  const shiftTypeColor = getShiftTagColor(shiftType);
  const { addUserJourneyEvent } = useShiftInviteContext();

  return (
    <Stack sx={{ position: "relative" }}>
      <Box
        sx={{
          padding: 1,
          paddingTop: 2,
          paddingBottom: 2,
          border: "1px solid",
          borderRadius: 1,
          borderColor: isSelected ? shiftTypeColor : "transparent",
          boxShadow: (theme) => theme.shadows[3],
        }}
      >
        <Stack spacing={2} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  if (isSelected) {
                    setTimeError(undefined);
                    onShiftInfoChange({ isSelected: false, start: startTime, end: endTime });
                    addUserJourneyEvent("deselect", `Shift Type ${shiftType}`);
                  }
                }}
                sx={{
                  "&.Mui-checked": {
                    color: shiftTypeColor,
                  },
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <Text bold color={(theme) => (isSelected ? shiftTypeColor : theme.palette.grey[500])}>
                {shiftType.toUpperCase()}
              </Text>
            }
            sx={{ minWidth: "70px" }}
          />
          <MultiInputTimeRangeField
            value={[startTime, endTime]}
            slotProps={{
              textField: ({ position }) => ({
                label:
                  position === "start"
                    ? "Start time"
                    : `End time${isSameDay(startTime, endTime) ? "" : " (next day)"}`,
                size: "small",
              }),
              root: { spacing: 1 },
            }}
            onChange={([start, end]) => {
              const newEnd =
                isDefined(start) && isDefined(end)
                  ? normalizeShiftEndTime(max([start, nowDateTime]), end)
                  : end;
              onShiftInfoChange({ start: start ?? undefined, end: newEnd ?? undefined });
            }}
            disableIgnoringDatePartForTimeValidation={true}
            minTime={isSelected ? nowDateTime : undefined}
            onError={([startError, _endError]) => setTimeError(startError)}
            disabled={!isSelected}
            formatDensity="dense"
            onFocus={() => {
              addUserJourneyEvent("start typing", `Shift Type ${shiftType} Time Selection`);
            }}
            onBlur={() => {
              addUserJourneyEvent("end typing", `Shift Type ${shiftType} Time Selection`);
            }}
          />
        </Stack>
      </Box>
      {!isSelected && (
        <Box
          data-testid={`select-shift-type-${shiftType}`}
          onClick={() => {
            onShiftInfoChange({ isSelected: true, start: startTime, end: endTime });
            addUserJourneyEvent("select", `Shift Type ${shiftType}`);
          }}
          sx={{
            position: "absolute",
            inset: 0,
            zIndex: 1,
            backgroundColor: "transparent",
            borderRadius: 1,
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
          }}
        />
      )}
      {showWarningMessage && (
        <Box paddingTop={1}>
          {showTimeError && (
            <Text variant="subtitle2" color="error">
              Shift start time has already passed
            </Text>
          )}
          {showDurationWarning && (
            <Text variant="subtitle2" color={(theme) => theme.palette.warning.main}>
              This shift is {duration} hours
            </Text>
          )}
        </Box>
      )}
    </Stack>
  );
}
