import { AppV2Routes } from "@src/appV2";
import { cbhQueryClient } from "@src/appV2/api/queryClient";
import { isProductionNodeEnvironment } from "@src/appV2/environment";
import { HOME_HEALTH_CASES_PATH } from "@src/appV2/ExperimentalHomeHealth/Cases/paths";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { Status } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { useInitializeLaunchDarkly } from "@src/hooks/useInitializeLaunchDarkly";
import { SessionType } from "@src/modules/interface";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Badge, Button, Drawer, Layout, Modal, Spin } from "antd";
import { useFlags } from "launchdarkly-react-client-sdk";
import { get as _get, includes } from "lodash";
import qs from "query-string";
import { Fragment, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { Link, Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { FilePreviewList } from "src/components/fileUploader/filePreviewList";
import { WorkerRatingPerformanceModal } from "src/components/WorkerRatingPerformanceModal/WorkerRatingPerformanceModal";
import { FACILITY_ROLES, FACILITY_USER_PERMISSIONS } from "src/constants/facility";
import { CarouselBanner } from "src/containers/app/carouselBanner/carouselBanner";
import { evaluateWorkplaceToWorkplaceReferrals } from "src/containers/workplaceReferAndEarn/utils";
import { WorkplaceSignContractPromptModal } from "src/containers/workplaceSignContract/components/PromptModal";
import { useInitializeMonitoring } from "src/hooks";
import { useFacilityOnboarding } from "src/hooks/useFacilityOnboarding";
import { useNotificationCenterFlag } from "src/hooks/useNotificationCenterFlag";
import { useRedirectToContractPage } from "src/hooks/useRedirectToContractPage";
import { initBraze } from "src/utils/braze";
import { loadLazily } from "src/utils/loadLazily";
import { getLocation, routes } from "src/utils/routes";
import styled from "styled-components";
import { FallbackLoader, LazyLoad } from "../../components/lazyLoad";
import { BunnyLogo, ClipboardLogo } from "../../components/logos";
import { PrivateRoute } from "../../components/routing";
import { EMPLOYEE_ROLES } from "@src/appV2/Employee/constants";
import { HamburgerIcon } from "../../icons/hamburger";
import { HIDE_SHIFT_TIMECARD_MODAL, HIDE_WORKER_REVIEW_MODAL } from "../../modules/shifts";

import { ChatPage } from "../chat/chat";
import { signInWithCustomToken } from "../enter/firebase";
import { FacilityUser } from "../facilityUser";
import { AccountSwitcher } from "./accountSwitcher/accountSwitcher";
import { AgentExperience } from "./agentExperience";
import { EnvironmentRibbon } from "./EnvironmentRibbon";
import { FacilityAccountSwitcher } from "./FacilityAccountSwitcher";
import { FacilityAppDownloadBanner } from "./facilityAppDownloadBanner/FacilityAppDownloadBanner";
import { AppFooter } from "./footer";
import { useLogoutIn24Hours } from "./hooks/useLogoutIn24Hours";
import { useReloadPageOnUserSwitch } from "./hooks/useReloadPageOnUserSwitch";
import { LocalTime } from "./LocalTime";
import { AppMenu } from "./menu";
import { NotificationCenterButton } from "./NotificationCenter/NotificationCenterButton";
import { HeaderContainer, RightContainerOfHeader } from "./styles";
import { CopyableTextField } from "@src/appV2/CopyableTextField/CopyableTextField";
import { Button as MuiButton, Stack } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { useRecentlyVisited } from "@src/hooks/useRecentlyVisited";
import { useAccountSwitcherHook } from "./accountSwitcher/useAccountSwitcherHook";
import { FacilityTypes } from "@src/utils/facility";
import { NewWebVersionDialog } from "@src/appV2/components/NewWebVersionDialog/NewWebVersionDialog";
import { isDefined } from "@clipboard-health/util-ts";
import { useIsJobInterviewsEnabled } from "@src/appV2/InterviewSettings/useIsJobInterviewsEnabled";

const { Content, Sider } = Layout;

const LOGIN_PATHS = [routes.loginVerify, routes.enter, routes.loginWithEmailPassword];
const SHOW_CONTENTS_PATHS = [routes.facilityChat, routes.workplaceWorkers];
const PATHS_WITH_NEW_LAYOUT = [
  routes.facilityOnboarding,
  routes.workersDirectoryWorkerDetails,
  routes.workersDirectory,
  routes.workplaceBlockShifts,
  routes.workplaceSignContract,
  routes.workplaceReferAndEarn,
  routes.workplaceHireProfessionals,
  routes.workplaceHireProfessionalDetails,
  routes.jobsLandingPage,
  routes.manageJobs,
  routes.interviewSettings,
  routes.adminQRCode,
];

const FirebaseLoginWithPassword = loadLazily(() =>
  import("../enter/commonLogin/firebaseLoginWithPassword").then((module) => ({
    default: module.FirebaseLoginWithPassword,
  }))
);

const AdminAppLogin = loadLazily(() =>
  /* @ts-expect-error FIXME this wrong type */
  import("../enter/commonLogin").then((module) => ({
    default: module.AdminAppLogin,
  }))
);

const FirebaseLoginVerify = loadLazily(() =>
  import("../enter/commonLogin").then((module) => ({
    default: module.FirebaseLoginVerify,
  }))
);

const AgentDashboard = loadLazily(() =>
  import("../agentDashboard").then((module) => ({
    default: module.AgentDashboard,
  }))
);

const AdminDashboard = loadLazily(() =>
  /* @ts-expect-error FIXME this wrong type */
  import("../adminDashboard").then((module) => ({
    default: module.AdminDashboard,
  }))
);

const WorkersDirectoryWorker = loadLazily(() =>
  import("../workplace/WorkerDetails").then((module) => ({
    default: module.WorkerDetailsPage,
  }))
);

const WorkersDirectory = loadLazily(() =>
  import("../workplace/WorkersDirectory/WorkersDirectory").then((module) => ({
    default: module.WorkersDirectoryPage,
  }))
);

const ListWorkersWorker = loadLazily(() =>
  import("../workers/WorkerDetails").then((module) => ({
    default: module.WorkerDetailsPage,
  }))
);

const ListWorkers = loadLazily(() =>
  import("../workers/ListWorkers/ListWorkers").then((module) => ({
    default: module.ListWorkersPage,
  }))
);

const MyProfessionalsPageWrapper = loadLazily(() =>
  import("../workers/Professionals/MyProfessionals/MyProfessionalsPageWrapper").then((module) => ({
    default: module.MyProfessionalsPageWrapper,
  }))
);

const WorkplaceSignContract = loadLazily(() =>
  import("../workplaceSignContract/WorkplaceSignContract").then((module) => ({
    default: module.WorkplaceSignContractPage,
  }))
);

const TimeclockRequests = loadLazily(() =>
  import("../timeclockrequests").then((module) => ({
    default: module.TimeclockRequests,
  }))
);

const ReferenceForm = loadLazily(() =>
  import("../referenceForm").then((module) => ({
    default: module.ReferenceForm,
  }))
);

const Profile = LazyLoad(loadLazily(() => import("../completeProfile")));

const DownloadICAgreement = LazyLoad(
  loadLazily(() => import("../icAgreement/downloadIcAgreement"))
);

const EditProfile = loadLazily(() => import("../editProfile"));
const FacilityUserProfile = loadLazily(() =>
  import("../userProfile/facility").then((module) => ({
    default: module.FacilityUserProfile,
  }))
);

const Payroll = loadLazily(() => import("../payroll/payrollView"));
const ProofOfEarnings = loadLazily(() => import("../proofOfEarnings"));

const StripePayouts = loadLazily(() => import("../payroll/stripePayouts/stripePayouts"));

const StripeTransfers = loadLazily(() => import("../payroll/stripeTransfers/stripeTransfers"));

const attendanceScore = loadLazily(() => import("../attendanceScore"));

const FacilityOnboarding = loadLazily(() =>
  import("../facilityOnboarding/FacilityOnboarding").then((module) => ({
    default: module.FacilityOnboarding,
  }))
);

const FacilityDashboard = loadLazily(() =>
  import("../facilityDashboard").then((module) => ({
    default: module.FacilityDashboard,
  }))
);

const FacilityInvoiceHistory = loadLazily(() =>
  import("../../appV2/FacilityInvoiceHistory").then((module) => ({
    default: module.FacilityInvoiceHistory,
  }))
);

const FacilityInvoiceDispute = loadLazily(() =>
  import("../../appV2/FacilityInvoiceDispute").then((module) => ({
    default: module.FacilityInvoiceDisputeContainer,
  }))
);

const FacilityInvoiceDisputeRedirect = loadLazily(() =>
  import("../../appV2/FacilityInvoiceDispute/FacilityInvoiceDisputeRedirect").then((module) => ({
    default: module.FacilityInvoiceDisputeRedirect,
  }))
);

const AdminDisputePage = loadLazily(() =>
  import("../../appV2/FacilityInvoiceDispute/AdminInvoiceDispute/AdminDisputePage").then(
    (module) => ({
      default: module.AdminDisputePage,
    })
  )
);

const FacilityWorkerInstructions = loadLazily(() =>
  import("../facilityWorkerInstructions").then((module) => ({
    default: module.FacilityWorkerInstructions,
  }))
);

const FacilityRulesPage = loadLazily(() => {
  return import("../../appV2/FacilityRules/Page").then((module) => ({
    default: module.FacilityRulesPage,
  }));
});

const FacilityTimeClockSettings = loadLazily(() =>
  import("../workplaceSettings").then((module) => ({
    default: module.CustomSettings,
  }))
);

const FacilitySettings = loadLazily(() =>
  import("../facilityDashboard/FacilitySettings/Page").then((module) => ({
    default: module.FacilitySettingsPage,
  }))
);

const FacilityUpcomingChargesV2 = loadLazily(() =>
  import("../../appV2/FacilityUpcomingCharges").then((module) => ({
    default: module.FacilityUpcomingCharges,
  }))
);

const ContractTerms = loadLazily(() =>
  import("../contractTerms").then((module) => ({
    default: module.ContractTerms,
  }))
);

const LiveMapView = loadLazily(() =>
  import("../liveMapView").then((module) => ({
    default: module.LiveMapView,
  }))
);

const Logs = loadLazily(() => import("../logs").then((module) => ({ default: module.Logs })));

const ShiftPayments = loadLazily(() =>
  import("../shiftPayments").then((module) => ({
    default: module.ShiftPayments,
  }))
);

const Accounts = loadLazily(() => import("../accountManagement"));

const PDFGenerator = loadLazily(() =>
  import("../accountManagement/invoice/pdfGenerator").then((module) => ({
    default: module.PDFGenerator,
  }))
);

const MyAccount = loadLazily(() =>
  import("../myAccount").then((module) => ({
    default: module.MyAccount,
  }))
);

const TeamMembers = loadLazily(() =>
  /* @ts-expect-error FIXME this wrong type */
  import("../teamMembers").then((module) => ({
    default: module.TeamMembers,
  }))
);

const InvoiceView = loadLazily(() =>
  import("../../components/invoiceView").then((module) => ({
    default: module.InvoiceView,
  }))
);

const WorkerLanding = loadLazily(() => import("../workerLanding"));

const DocumentEdit = loadLazily(() =>
  import("../documents").then((module) => ({ default: module.DocumentEdit }))
);

const TimesheetView = loadLazily(() =>
  import("../accountManagement/invoice/timesheetView").then((module) => ({
    default: module.TimesheetView,
  }))
);

const TimesheetViewRedirect = loadLazily(() =>
  import("../accountManagement/invoice/timesheetViewRedirect").then((module) => ({
    default: module.TimesheetViewRedirect,
  }))
);

const AdminCalendar = loadLazily(() =>
  /* @ts-expect-error FIXME this wrong type */
  import("../calendar").then((module) => ({
    default: module.AdminCalendar,
  }))
);

const UrgentShiftsNoShowCases = loadLazily(() =>
  import("../urgentShiftsNoShowCases").then((module) => ({
    default: module.UrgentShiftsNoShowCases,
  }))
);

const WorkplaceBlockShifts = loadLazily(() =>
  import("../workplaceBlockShifts/WorkplaceBlockShifts").then((module) => ({
    default: module.WorkplaceBlockShifts,
  }))
);

const WorkplaceReferAndEarn = loadLazily(() =>
  import("../workplaceReferAndEarn/WorkplaceReferAndEarn").then((module) => ({
    default: module.WorkplaceReferAndEarn,
  }))
);

const AdminPostInvoiceDispute = loadLazily(() =>
  import("@src/appV2/FacilityInvoiceDispute/AdminInvoiceDispute/AdminPostInvoiceDisputes").then(
    (module) => ({
      default: module.AdminPostInvoiceDisputes,
    })
  )
);

const AdminPreInvoiceDispute = loadLazily(() =>
  import("@src/appV2/FacilityInvoiceDispute/AdminInvoiceDispute/AdminPreInvoiceDisputes").then(
    (module) => ({
      default: module.AdminPreInvoiceDisputes,
    })
  )
);

const AdminPreInvoiceDisputeResolution = loadLazily(() =>
  import(
    "../../appV2/FacilityInvoiceDispute/AdminInvoiceDispute/AdminPreInvoiceDisputeResolution"
  ).then((module) => ({
    default: module.AdminPreInvoiceDisputeResolution,
  }))
);

const WorkerDocuments = loadLazily(() =>
  import("../documents/documentView/WorkerDocumentsContainer").then((module) => ({
    default: module.WorkerDocumentsContainer,
  }))
);

const ManageQualifications = loadLazily(() =>
  import("../../appV2/Admin").then((module) => ({
    default: module.ManageQualificationsPage,
  }))
);

const ManageWorkplaceTypes = loadLazily(() =>
  import("../../appV2/Admin").then((module) => ({
    default: module.ManageWorkplaceTypesPage,
  }))
);

const ManageSkillsAssessments = loadLazily(() =>
  import("../../appV2/Admin").then((module) => ({
    default: module.ManageSkillsAssessmentsPage,
  }))
);

const PlacementCandidates = loadLazily(() =>
  import("../../appV2/PlacementCandidates/Page").then((module) => ({
    default: module.PlacementCandidatesPage,
  }))
);

const AdminPlacementCandidatePage = loadLazily(() =>
  import("../../appV2/PlacementCandidates/AdminPlacementCandidatePage").then((module) => ({
    default: module.AdminPlacementCandidatePage,
  }))
);

const PlacementCandidateDetailsPage = loadLazily(() =>
  import("../../appV2/PlacementCandidates/PlacementCandidateDetailsPage").then((module) => ({
    default: module.PlacementCandidateDetailsPage,
  }))
);

const JobsLandingPage = loadLazily(() =>
  import("../../appV2/JobsLandingPage/Page").then((module) => ({
    default: module.JobsLandingPage,
  }))
);

const ManageJobsTabs = loadLazily(() =>
  import("../../appV2/ManageJobs/ManageJobsTabs").then((module) => ({
    default: module.ManageJobsTabs,
  }))
);

const InterviewSettings = loadLazily(() =>
  import("../../appV2/InterviewSettings/Page").then((module) => ({
    default: module.InterviewSettingsPage,
  }))
);

const AdminQRCode = loadLazily(() =>
  import("../../appV2/QRCode/QRCodePage").then((module) => ({
    default: module.QRCodePage,
  }))
);

const ShiftSignoutFeedbackForm = loadLazily(() =>
  import("../ShiftSignoutFlow/shiftSignoutFlowForm").then((module) => ({
    default: module.ShiftSignoutFlowForm,
  }))
);

const SideMenuDrawer = styled(Drawer)`
  .ant-drawer-content,
  .ant-drawer-header {
    background: #001529;
  }

  .logo {
    display: inline-block;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-select {
    margin: 20px;
  }
`;

const Trigger = styled(HamburgerIcon)`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
`;
const ResponsiveDiv = styled.div`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
  display: none !important;
  @media (max-width: 992px) {
    display: inline-block !important;
  }
`;

const LogoContainer = styled(Link)`
  margin: 0 20px;
  line-height: 0;
  float: left;
  min-width: fit-content;
  .logo {
    display: inline-block;
  }

  @media (max-width: 992px) {
    display: block;
    text-align: center;
    margin: 0;
    float: none;
  }
`;

export const isTruthyish = (param: boolean | string) =>
  param && ["true", true, "1", 1].includes(param);

function AppLayout({
  admin,
  isAdmin,
  isAgent,
  isFacility,
  isSuspended,
  hasSession,
  location,
  session,
  get,
  getAdminProfile,
  loadingAdminProfile,
  initFirebaseSession,
  isFirebaseSession,
  getLocations,
  getEmployees,
  logout,
  ...props
}) {
  const [collapsed, setCollapsed] = useState({
    drawer: true,
    sideMenu: isTruthyish(localStorage.isSideMenuCollapsed) || false,
  });
  const [scrollState, setScrollState] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    /*
      0 - Mobile (Phone Portrait)
      1 - Small (Phone Landscape)
      2 - Medium (Tablet)
      3 - Large (Standard Desktop)
      4 - XLarge
    */
    size: 3,
  });
  const [requestedURL, setRequestedURL] = useState<string | null>(null);

  const { access, permissions } = useSelector((state: any) => state.session.user || {});
  const { workerReview, timecardsListPreview } = useSelector((state: any) => state.shifts);

  const { showModal: showWorkplaceContractModal } = useSelector(
    (state: any) => state.workplaceContract
  );

  const dispatch = useDispatch();

  const Location = useLocation();
  const history = useHistory();
  const pathName = Location.pathname;
  const qString = qs.parse(location.search);
  const [isToken, setToken] = useState<any>(qString.token);
  const [loading, setLoading] = useState(true);
  const { ready, userId, user, profile, type } = useSelector(
    (state: SessionType) => state.session || {}
  );
  const isAuthorized = isTruthyish(isAdmin || isAgent || isFacility);

  useInitializeLaunchDarkly({ profile, isAdmin });

  const ldFlags = useFlags();

  const isPayrollViewEnabled = !useCbhFlag(CbhFeatureFlag.ADMIN_APP_HIDE_PAYROLL_TABLE, {
    defaultValue: false,
  });

  const isStripeTransfersViewEnabled = useCbhFlag(CbhFeatureFlag.UI_STRIPE_TRANSFERS, {
    defaultValue: false,
  });
  const { showNotificationCenter } = useNotificationCenterFlag({
    isAdmin: admin,
  });
  const { minimumWorkplaceCreationDate } = useCbhFlag(CbhFeatureFlag.HCF_ONBOARDING, {
    defaultValue: {},
  });

  const isFacilityRulesAiEnabled = useCbhFlag(CbhFeatureFlag.ROLLOUT_QUIZ_V2_FACILITY_RULES_AI, {
    defaultValue: false,
  });

  const aTeamConfig = useCbhFlag(CbhFeatureFlag.A_TEAM_CONFIG, {
    defaultValue: { status: Status.OFF },
  });

  const isSkillsAssessmentsEnabled = useCbhFlag(CbhFeatureFlag.SKILL_ASSESSMENTS_GRADERS, {
    defaultValue: { allowedUsers: [] },
  });

  const isSkillsAssessmentsEnabledForUser = useMemo(
    () => isSkillsAssessmentsEnabled.allowedUsers.includes(session.user?._id),
    [isSkillsAssessmentsEnabled.allowedUsers, session.user?._id]
  );

  const [brazeInitialized, setBrazeInitialized] = useState(false);
  const { evaluateAndSetRedirectUrlIntoLocalStorage } = useRedirectToContractPage();

  const checkForAccessToken = useCallback(async (token) => {
    const result = await signInWithCustomToken(token);
    /* @ts-expect-error FIXME this wrong type */
    if (result.error) {
      setToken(false);
    } else {
      setToken(true);
    }
  }, []);

  const closeShiftTimecardModal = useCallback(() => {
    dispatch({ type: HIDE_SHIFT_TIMECARD_MODAL });
  }, [dispatch]);

  useInitializeMonitoring();

  const closeWorkerReviewModal = useCallback(() => {
    dispatch({ type: HIDE_WORKER_REVIEW_MODAL });
  }, [dispatch]);

  const [recentVisited] = useRecentlyVisited();
  const { handleChange } = useAccountSwitcherHook({ pseudo: props.pseudo, closeMenu: true });

  useEffect(() => {
    setRequestedURL(pathName);
  }, [pathName]);

  useEffect(() => {
    const width = window.innerWidth;
    let size = 4;

    if (width < 576) {
      size = 0;
    } else if (width < 768) {
      size = 1;
    } else if (width < 992) {
      size = 2;
    } else if (width < 1200) {
      size = 3;
    }

    setScrollState((state) => ({ ...state, size }));
  }, []);

  useEffect(() => {
    // Workaround to avoid issues with new roles DB field. If user was
    // loggedIn and is an admin, we fetch the new admin profile to have
    // the roles field and prevent errors
    if (loadingAdminProfile) {
      getAdminProfile();
    }
  }, [loadingAdminProfile, getAdminProfile]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await initFirebaseSession();
      setLoading(false);
    }
    load();
  }, [initFirebaseSession]);

  useEffect(() => {
    if (session?.user?._id && !session.admin) {
      async function load() {
        setLoading(true);
        await initBraze(session?.user?._id);
        setLoading(false);
        setBrazeInitialized(true);
      }
      load();
    }
    return () => setBrazeInitialized(false);
  }, [session.admin, session?.user?._id]);

  useEffect(() => {
    if (loading || !ready) {
      return;
    }
    if (!isAuthorized) {
      return;
    }
    get();
    getLocations();

    if (session?.admin) {
      getEmployees();
    }
  }, [isAuthorized, get, getLocations, getEmployees, loading, ready, session?.admin]);

  /**
   * logout employee which are part of feature flag below in 24 hours.
   */
  useLogoutIn24Hours();

  useReloadPageOnUserSwitch();

  const isHomeHealthEnabled = useIsHomeHealthEnabled();
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();
  useEffect(() => {
    if (loading || !ready) {
      return;
    }
    if (isToken) {
      checkForAccessToken(isToken);
    }
  }, [checkForAccessToken, loading, ready]);

  const { channels } = useSelector((state: any) => state.chat);
  useFacilityOnboarding(
    profile,
    (includes(access, FACILITY_ROLES.ADMIN) || includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT)) &&
      !admin,
    admin,
    isFacility
  );

  let unreadChannelCount = 0;
  for (const channel of channels) {
    if (channel.unreadMessageCount) {
      unreadChannelCount += channel.unreadMessageCount;
    }
  }

  const toggleCollapsible = (field) => () => {
    setCollapsed((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    if (field === "sideMenu") {
      /* @ts-expect-error FIXME this wrong type */
      localStorage.setItem("isSideMenuCollapsed", !collapsed.sideMenu);
    }
  };

  const getUrlParams = () => {
    if (!location?.search) {
      return {};
    }
    return qs.parse(location.search);
  };

  const renderAgentDashboardRoute = () => {
    if (isAgent && !admin) {
      return <AgentExperience />;
    }

    if (isAgent) {
      /* @ts-expect-error FIXME this wrong type */
      return <AgentDashboard {...scrollState} />;
    }
    // pass url params through to /enter
    return <Redirect to={`/enter${location.search}`} />;
  };

  const urlParams = getUrlParams();
  const noScroll = "noScroll" in urlParams;

  const renderEnter = () => {
    const { date } = qs.parse(location.search);
    if (isAgent) {
      if (urlParams.url) {
        /* @ts-expect-error FIXME this wrong type */
        window.location.href = urlParams.url;
      } else {
        return (
          <Redirect
            /* @ts-expect-error FIXME this wrong type */
            to={getLocation("agentDashboard", {
              queryParams: date ? { date } : undefined,
            })}
          />
        );
      }
    } else if (isFacility) {
      if (isHomeHealthEnabled) {
        return <Redirect to={`/v2/home/${HOME_HEALTH_CASES_PATH}`} />;
      }

      const isOnlyRecruiter =
        includes(access, FACILITY_ROLES.RECRUITMENT) && !hasDefaultFacilityPermissions;
      if (isOnlyRecruiter) {
        return <Redirect to={getLocation("workplaceHireProfessionals")} />;
      }
      return (
        <Redirect
          /* @ts-expect-error FIXME this wrong type */
          to={getLocation("facilityDashboard", {
            queryParams: date ? { date } : undefined,
          })}
        />
      );
    } else if (isAdmin) {
      return <Redirect to={getLocation("adminProfile")} />;
    }

    evaluateAndSetRedirectUrlIntoLocalStorage();
    if (urlParams["adminId"]) {
      // no session...we were re-directed here from /admin/dashboard
      get({ userId: urlParams["adminId"], admin: true });
    }
    /* @ts-expect-error FIXME this wrong type */
    return <AdminAppLogin requestedURL={requestedURL} />;
  };

  if (loadingAdminProfile) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  const isDocumentAuthorized = () => {
    if (_get(session, "adminProfile.roles", []).includes(EMPLOYEE_ROLES.DOCUMENTS)) {
      return true;
    } else if (isFacility) {
      if (!isFirebaseSession) {
        return true;
      }
      if (
        includes(access, FACILITY_ROLES.ADMIN) ||
        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
        includes(access, FACILITY_ROLES.DOCUMENTS)
      ) {
        return true;
      }
    }
    return false;
  };

  const isLoginPath = LOGIN_PATHS.includes(location.pathname);

  if (isFacility && isFirebaseSession && access === undefined) {
    return null;
  }

  const isInvoiceViewAuthorized = () => {
    if (
      includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.INVOICES) ||
      includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES)
    ) {
      return true;
    }

    if (isFacility && !isFirebaseSession) {
      return true;
    }

    return false;
  };

  const showAccountSwitcher = !noScroll && session.admin;
  const showFacilitySwitcher = !noScroll && isFacility && isFirebaseSession;
  if ((loading || !ready) && session.userId) {
    return null;
  }

  const showsBadge =
    includes(access, FACILITY_ROLES.ADMIN) ||
    includes(access, FACILITY_ROLES.DOCUMENTS) ||
    includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT);
  const cssMainDisplay = SHOW_CONTENTS_PATHS.find((path) => location.pathname.startsWith(path))
    ? "contents"
    : "block";

  const configuration = {
    isPayrollViewEnabled,
    isStripeTransfersViewEnabled,
  };
  const isRevampPages = PATHS_WITH_NEW_LAYOUT.some((path) =>
    matchPath(pathName, { path, exact: true })
  );

  const workplaceInOnboardingExperiment =
    minimumWorkplaceCreationDate && profile?.createdAt
      ? new Date(profile.createdAt) > new Date(minimumWorkplaceCreationDate)
      : false;
  const hasPermissionToAccessSelfOnboardingPages =
    isFacility &&
    workplaceInOnboardingExperiment &&
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
      admin);
  const hasPermissionToAccessWorkerDirectory =
    isFacility &&
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
      includes(access, FACILITY_ROLES.RECRUITMENT) ||
      admin);
  const hasDefaultFacilityPermissions =
    isFacility &&
    ([
      FACILITY_ROLES.ADMIN,
      FACILITY_ROLES.SHIFT_MANAGEMENT,
      FACILITY_ROLES.DOCUMENTS,
      FACILITY_ROLES.INVOICES,
    ].some((role) => includes(access, role)) ||
      admin);

  const isWorkplaceToWorkplaceReferralsEnabled = evaluateWorkplaceToWorkplaceReferrals(
    ldFlags,
    user,
    isFacility,
    admin
  );

  const hasPermissionToAccessFacilitySettingsPage = includes(access, FACILITY_ROLES.ADMIN) || admin;

  const JUST_CLIPBOARD_LOGO_URL = `${process.env.REACT_APP_BASE_PATH || "/"}justClipboard.png`;
  const BUNNY_EDUCATION_LOGO_URL = `${process.env.REACT_APP_BASE_PATH || "/"}bunnyEducation.png`;

  return (
    <Fragment>
      <SideMenuDrawer
        title={
          <LogoContainer to={routes.enter}>
            <ClipboardLogo
              height={63}
              width={100}
              url={
                profile?.type === FacilityTypes.SCHOOL ||
                profile?.type === FacilityTypes.CHILD_CARE_CENTER ||
                !isDefined(profile?.type)
                  ? JUST_CLIPBOARD_LOGO_URL
                  : undefined
              }
            />
            <BunnyLogo
              height={63}
              width={70}
              url={
                profile?.type === FacilityTypes.SCHOOL ||
                profile?.type === FacilityTypes.CHILD_CARE_CENTER
                  ? BUNNY_EDUCATION_LOGO_URL
                  : undefined
              }
            />
          </LogoContainer>
        }
        placement="left"
        onClose={toggleCollapsible("drawer")}
        visible={!collapsed.drawer}
      >
        {showAccountSwitcher && (
          /* @ts-expect-error FIXME this wrong type */
          <AccountSwitcher
            adminUserId={session.adminUserId}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {showFacilitySwitcher && (
          <FacilityAccountSwitcher
            userId={session.userId}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {!noScroll && (
          <RightContainerOfHeader>
            <LocalTime timeZone={props.tz} />
            {showNotificationCenter && (
              <NotificationCenterButton brazeInitialized={brazeInitialized} />
            )}
          </RightContainerOfHeader>
        )}
        {!noScroll && (
          /* @ts-expect-error FIXME this wrong type */
          <AppMenu
            style={{ width: 256 }}
            isAdmin={isAdmin}
            isAgent={isAgent}
            isFacility={isFacility}
            logout={logout}
            hasSession={hasSession}
            profile={props.profile}
            configuration={configuration}
            closeMenu={toggleCollapsible("drawer")}
            unreadChannelCount={unreadChannelCount}
            emailOfLoggedInUser={session?.user?.email}
          />
        )}
      </SideMenuDrawer>
      <div>
        {/* @ts-expect-error FIXME this wrong type */}
        <Layout style={noScroll ? null : { height: "100vh" }}>
          <FacilityAppDownloadBanner />
          <EnvironmentRibbon />
          <HeaderContainer>
            {hasSession && (
              <ResponsiveDiv style={{ position: "absolute", right: 0 }}>
                <Trigger className="trigger" onClick={toggleCollapsible("drawer")} />
                {showsBadge && (
                  <Badge
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#e94a4d",
                      position: "relative",
                      top: -25,
                      right: 20,
                    }}
                    count={unreadChannelCount}
                  />
                )}
              </ResponsiveDiv>
            )}
            <LogoContainer to={routes.enter}>
              <ClipboardLogo
                height={60}
                width={140}
                url={
                  profile?.type === FacilityTypes.SCHOOL ||
                  profile?.type === FacilityTypes.CHILD_CARE_CENTER ||
                  !isDefined(profile?.type)
                    ? JUST_CLIPBOARD_LOGO_URL
                    : undefined
                }
              />
              <BunnyLogo
                height={63}
                width={60}
                url={
                  profile?.type === FacilityTypes.SCHOOL ||
                  profile?.type === FacilityTypes.CHILD_CARE_CENTER
                    ? BUNNY_EDUCATION_LOGO_URL
                    : undefined
                }
              />
            </LogoContainer>
            {showAccountSwitcher && (
              /* @ts-expect-error FIXME this wrong type */
              <AccountSwitcher
                adminUserId={session.adminUserId}
                pseudo={props.pseudo}
                className="hide-on-med-and-down"
              />
            )}
            {showFacilitySwitcher && (
              /* @ts-expect-error FIXME this wrong type */
              <FacilityAccountSwitcher
                userId={session.userId}
                pseudo={props.pseudo}
                adjustUIForSmallScreens={true}
              />
            )}
            {!noScroll && (
              <RightContainerOfHeader className="hide-on-med-and-down">
                <LocalTime timeZone={props.tz} />
                {showNotificationCenter && (
                  <NotificationCenterButton brazeInitialized={brazeInitialized} />
                )}
              </RightContainerOfHeader>
            )}
          </HeaderContainer>
          <Layout className={isRevampPages ? "layout-v1" : ""}>
            {isAuthorized && !noScroll && (
              <Sider
                style={{ overflowY: "auto" }}
                width="15vw"
                className="hide-on-med-and-down"
                collapsible
                collapsed={collapsed.sideMenu}
                onCollapse={toggleCollapsible("sideMenu")}
              >
                {/* @ts-expect-error FIXME this wrong type */}
                <AppMenu
                  className="hide-on-med-and-down"
                  admin={admin}
                  isAdmin={isAdmin}
                  isAgent={isAgent}
                  isFacility={isFacility}
                  logout={logout}
                  hasSession={hasSession}
                  configuration={configuration}
                  profile={props.profile}
                  mode="inline"
                  selectable={true}
                  enableDefaultSelectedKeys={true}
                  unreadChannelCount={unreadChannelCount}
                  emailOfLoggedInUser={session?.user?.email}
                />
                {admin && !collapsed.sideMenu && (
                  <>
                    {(isFacility || isAgent) && (
                      <Stack padding={2} gap={2}>
                        <CopyableTextField
                          label={isFacility ? "Facility Name" : "Professional Name"}
                          value={profile.name}
                        />
                        <CopyableTextField
                          label={isFacility ? "Facility ID" : "Professional ID"}
                          value={profile.userId}
                        />
                      </Stack>
                    )}

                    <span style={{ fontSize: "14px", marginLeft: "18px", color: "#ffffffa6" }}>
                      Recently Viewed
                    </span>
                    <Stack padding={2} gap={2}>
                      {/* if you switch to admin view, the latest recent item is no longer being viewed, so we shouldn't hide it */}
                      {recentVisited?.slice(isAdmin ? 0 : 1).map((item) => (
                        <MuiButton
                          key={item.id}
                          onClick={() =>
                            handleChange({
                              user: { userId: item.id, name: item.title },
                              type: item.type,
                            })
                          }
                          sx={{ color: "#ffffffa6" }}
                          variant="contained"
                          endIcon={<LaunchIcon />}
                        >
                          <span>{item.title}</span>
                        </MuiButton>
                      ))}
                    </Stack>
                  </>
                )}
              </Sider>
            )}
            <Content
              id="content-box"
              style={{ ...(noScroll ? null : { overflowY: "scroll" }) }}
              className={isLoginPath ? "full-screen" : ""}
            >
              {/*
                we only want to show the banner on facility dashboard page
              */}

              <PrivateRoute
                exact
                path={routes.facilityDashboard}
                component={CarouselBanner}
                isAuthorized={isFacility || admin}
              />

              <Route path={routes.appV2}>
                <QueryClientProvider client={cbhQueryClient}>
                  {!isProductionNodeEnvironment() && <ReactQueryDevtools />}
                  <AppV2Routes />
                </QueryClientProvider>
              </Route>

              <NewWebVersionDialog />
              <Suspense fallback={<FallbackLoader />}>
                <>
                  <PrivateRoute
                    exact
                    path={routes.workplaceWorkers}
                    component={
                      aTeamConfig.status === Status.ON ? MyProfessionalsPageWrapper : ListWorkers
                    }
                    isAuthorized={isFacility || admin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceReferAndEarn}
                    component={WorkplaceReferAndEarn}
                    isAuthorized={isWorkplaceToWorkplaceReferralsEnabled}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceWorkerDetails}
                    component={ListWorkersWorker}
                    isAuthorized={isFacility || admin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.workersDirectoryWorkerDetails}
                    component={WorkersDirectoryWorker}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />
                  <PrivateRoute
                    exact
                    path={routes.workplaceSignContract}
                    component={WorkplaceSignContract}
                    isAuthorized={hasDefaultFacilityPermissions || admin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.myAccount}
                    component={MyAccount}
                    isAuthorized={ldFlags?.[CbhFeatureFlag.MY_ACCOUNT_PAGE] && isFacility && !admin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceUsers}
                    component={TeamMembers}
                    isAuthorized={
                      ldFlags?.[CbhFeatureFlag.NEW_USERS_PAGE] &&
                      !(access?.includes("DMT") && access.length === 1) &&
                      (hasDefaultFacilityPermissions || admin)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityOnboarding}
                    component={FacilityOnboarding}
                    isAuthorized={hasPermissionToAccessSelfOnboardingPages}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workersDirectory}
                    component={WorkersDirectory}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceBlockShifts}
                    component={WorkplaceBlockShifts}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />
                  <PrivateRoute
                    exact
                    path={routes.workplaceHireProfessionals}
                    component={PlacementCandidates}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />
                  <PrivateRoute
                    exact
                    path={routes.jobsLandingPage}
                    component={JobsLandingPage}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />
                  <PrivateRoute
                    exact
                    path={routes.manageJobs}
                    component={ManageJobsTabs}
                    isAuthorized={hasPermissionToAccessWorkerDirectory}
                  />
                  <PrivateRoute
                    exact
                    path={routes.interviewSettings}
                    component={InterviewSettings}
                    isAuthorized={isJobInterviewsEnabled && hasPermissionToAccessWorkerDirectory}
                  />
                  <Route
                    exact
                    path={routes.workplaceHireProfessionalDetails}
                    component={PlacementCandidateDetailsPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminQRCode}
                    component={AdminQRCode}
                    isAuthorized={admin}
                  />
                </>
              </Suspense>
              <main style={{ display: cssMainDisplay }} id="legacy-routes">
                <Suspense fallback={<FallbackLoader />}>
                  <Route exact path={routes.root} render={() => <Redirect to={routes.enter} />} />
                  <Route exact path={routes.admin}>
                    <Redirect to={routes.enter} />
                  </Route>
                  <Route path={routes.reference} component={ReferenceForm} />
                  <Route path={routes.shiftSignoutFeedback} component={ShiftSignoutFeedbackForm} />
                  <PrivateRoute
                    exact
                    path={routes.loginVerify}
                    component={FirebaseLoginVerify}
                    isAuthorized={!isAuthorized}
                  />
                  <Route exact path={routes.workers} component={WorkerLanding} />
                  <PrivateRoute
                    exact
                    path={routes.document}
                    component={WorkerDocuments}
                    isAuthorized={isDocumentAuthorized()}
                  />
                  <PrivateRoute
                    exact
                    path={routes.calendar}
                    /* @ts-expect-error FIXME this wrong type */
                    component={() => <AdminCalendar pseudo={props.pseudo} />}
                    isAuthorized={isAdmin}
                    pseudo={props.pseudo}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminInvoice}
                    component={InvoiceView}
                    isAuthorized={isAdmin || isInvoiceViewAuthorized() || isToken}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminInvoicePdf}
                    component={PDFGenerator}
                    isAuthorized={isAdmin || isInvoiceViewAuthorized() || isToken}
                  />
                  <PrivateRoute
                    exact
                    path={routes.timesheetRedirect}
                    component={TimesheetViewRedirect}
                    isAuthorized={isAdmin || hasDefaultFacilityPermissions}
                  />
                  <Route exact path={routes.timesheet} component={TimesheetView} />
                  <PrivateRoute
                    exact
                    path={routes.timesheetPdf}
                    component={PDFGenerator}
                    isAuthorized={isAdmin || isInvoiceViewAuthorized() || isToken}
                  />
                  <Route path={routes.enter} location={location} render={renderEnter} />
                  <PrivateRoute
                    exact
                    path={routes.loginWithEmailPassword}
                    component={FirebaseLoginWithPassword}
                    isAuthorized={!isAuthorized}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityDashboard}
                    component={FacilityDashboard}
                    isAuthorized={hasDefaultFacilityPermissions}
                  />
                  <PrivateRoute
                    exact
                    path={routes.facilityLiveWorkerETA}
                    component={LiveMapView}
                    isAuthorized={
                      hasDefaultFacilityPermissions || hasPermissionToAccessSelfOnboardingPages
                    }
                  />
                  <PrivateRoute
                    exact
                    path={routes.facilityUsers}
                    component={FacilityUser}
                    isAuthorized={
                      !ldFlags?.[CbhFeatureFlag.NEW_USERS_PAGE] && (isFacility || admin)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityWorkerInstructions}
                    component={
                      isFacilityRulesAiEnabled ? FacilityRulesPage : FacilityWorkerInstructions
                    }
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityTimeclockSettings}
                    component={FacilityTimeClockSettings}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilitySettings}
                    component={FacilitySettings}
                    isAuthorized={hasPermissionToAccessFacilitySettingsPage}
                  />

                  <PrivateRoute
                    path={routes.facilityChatWithAgent}
                    component={ChatPage}
                    isAuthorized={
                      isFacility &&
                      (includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.DOCUMENTS) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
                        includes(access, FACILITY_ROLES.RECRUITMENT))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceHistory}
                    component={FacilityInvoiceHistory}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceDispute}
                    component={FacilityInvoiceDispute}
                    componentProps={{ isAdminView: false }}
                    isAuthorized={
                      isFacility &&
                      !admin &&
                      (includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceDisputeRedirect}
                    component={FacilityInvoiceDisputeRedirect}
                    isAuthorized={
                      isFacility &&
                      !admin &&
                      (includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminAccountsPostInvoiceDisputes}
                    component={AdminPostInvoiceDispute}
                    isAuthorized={
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.BILLING) ||
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminAccountsPreInvoiceDisputes}
                    component={AdminPreInvoiceDispute}
                    isAuthorized={
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.BILLING) ||
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminInvoiceDispute}
                    component={AdminDisputePage}
                    isAuthorized={
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.BILLING) ||
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminPreInvoiceDispute}
                    component={AdminPreInvoiceDisputeResolution}
                    isAuthorized={
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.BILLING) ||
                      session.adminProfile?.roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceUpcomingCharges}
                    component={FacilityUpcomingChargesV2}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
                        includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_UPCOMING_CHARGES))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceContractTerms}
                    component={ContractTerms}
                    isAuthorized={
                      isAdmin ||
                      (isFacility &&
                        (admin ||
                          includes(access, FACILITY_ROLES.ADMIN) ||
                          includes(access, FACILITY_ROLES.INVOICES) ||
                          includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_CONTRACT_TERMS)))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityProfile}
                    component={FacilityUserProfile}
                    isAuthorized={isFacility}
                  />

                  <Route
                    exact
                    path={routes.agentDashboard}
                    location={location}
                    render={renderAgentDashboardRoute}
                  />
                  {isPayrollViewEnabled && (
                    <PrivateRoute
                      path={routes.agentPayroll}
                      component={Payroll}
                      isAgent={!admin && isAgent}
                      isAuthorized={isAgent || isAdmin}
                    />
                  )}
                  <PrivateRoute
                    path={routes.agentProof}
                    component={ProofOfEarnings}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.agentStripePayouts}
                    component={StripePayouts}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />
                  {isStripeTransfersViewEnabled && (
                    <PrivateRoute
                      path={routes.agentStripeTransfers}
                      component={StripeTransfers}
                      isAgent={!admin && isAgent}
                      isAuthorized={isAgent}
                    />
                  )}
                  <PrivateRoute
                    path={routes.agentAttendance}
                    component={attendanceScore}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />

                  <PrivateRoute
                    path={routes.agentPlacement}
                    component={AdminPlacementCandidatePage}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />

                  <PrivateRoute
                    exact
                    path={routes.agentProfile}
                    component={EditProfile}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />

                  <PrivateRoute
                    exact
                    path={routes.agentICAgreement}
                    component={DownloadICAgreement}
                    isAuthorized={session.admin}
                  />

                  <PrivateRoute
                    path={routes.adminDashboard}
                    component={AdminDashboard}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminTimeclock}
                    component={TimeclockRequests}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminProfile}
                    component={EditProfile}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminAccounts}
                    component={Accounts}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute path={routes.adminLogs} component={Logs} isAuthorized={isAdmin} />
                  <PrivateRoute
                    path={routes.adminShiftPayments}
                    component={ShiftPayments}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminDocument}
                    component={DocumentEdit}
                    isAuthorized={session.adminProfile?.roles.includes(EMPLOYEE_ROLES.DOCUMENTS)}
                  />
                  <PrivateRoute
                    path={routes.adminUrgentShiftNcns}
                    component={UrgentShiftsNoShowCases}
                    isAuthorized={isAdmin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminManageQualifications}
                    component={ManageQualifications}
                    isAuthorized={isAdmin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminManageWorkplaceTypes}
                    component={ManageWorkplaceTypes}
                    isAuthorized={isAdmin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.adminManageSkillsAssessments}
                    component={ManageSkillsAssessments}
                    isAuthorized={isSkillsAssessmentsEnabledForUser}
                  />
                </Suspense>
              </main>
              <AppFooter className="hide-on-large-only" />
            </Content>
          </Layout>
          <AppFooter
            /* @ts-expect-error FIXME this wrong type */
            style={!noScroll ? { height: "48px", paddingTop: "16px" } : null}
            className="hide-on-med-and-down"
          />
        </Layout>
      </div>

      <Modal
        title="Complete your profile"
        visible={props.profile && props.profile.name === "" && !session.admin}
        closable={false}
        footer={null}
      >
        <Profile />
      </Modal>

      <Modal
        title="Welcome to Clipboard Health!"
        visible={"welcomeAgent" in urlParams}
        closable={false}
        onOk={() => history.push(location.path)}
        footer={[
          <Button key="submit" type="primary" onClick={() => history.push(location.path)}>
            Get Started
          </Button>,
        ]}
      >
        <p>
          Let’s make some money! Pick a day, pick a shift, and mark your interest.
          <br />
          <br />
          Love,
          <br />- The Clipboard Team
        </p>
      </Modal>
      {workerReview.showModal && user && (
        <WorkerRatingPerformanceModal
          visible={workerReview.showModal}
          workerId={workerReview.workerId}
          workplaceUserId={user._id}
          workplaceId={userId}
          timezone={profile?.tmz}
          actionBy={type}
          trackData={{
            ...workerReview.trackData,
            whoCalledMe: workerReview.whoCalledMe,
          }}
          onClose={closeWorkerReviewModal}
          successCallback={workerReview.successCallback}
        />
      )}
      {timecardsListPreview?.length > 0 && (
        <Modal
          visible={timecardsListPreview?.length > 0}
          footer={false}
          onCancel={closeShiftTimecardModal}
        >
          <FilePreviewList fileList={timecardsListPreview} />
        </Modal>
      )}
      {showWorkplaceContractModal && <WorkplaceSignContractPromptModal />}
    </Fragment>
  );
}

export { AppLayout };
