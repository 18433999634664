import React from "react";

function ClipboardLogo(props) {
  const urlString = props.url || `${process.env.REACT_APP_BASE_PATH || "/"}clipboard1_w.png`;
  return (
    <div
      className="logo"
      style={{
        float: props.float,
        display: props.display || "inline-block",
        height: props.height,
        width: props.width,
        background: `url("${urlString}") center center / contain no-repeat`,
        margin: props.margin || "0 0 0 0",
      }}
    />
  );
}

function BunnyLogo(props) {
  const urlString = props.url || `${process.env.REACT_APP_BASE_PATH || "/"}bunnyB.png`;
  return (
    <div
      className="logo"
      style={{
        float: props.float,
        height: props.height,
        width: props.width,
        background: `url("${urlString}") center center / contain no-repeat`,
        margin: props.margin || "0 0 0 0",
      }}
    />
  );
}

export { ClipboardLogo, BunnyLogo };
