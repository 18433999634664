import { UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import meta from "src/utils/meta";
import { useShiftInviteContext } from "../Context/shiftInviteContext";

const { supportPhone } = meta();

type ShiftInviteErrorDialogProps = {
  modalState: UseModalState;
  errorMessage?: string;
};

export function ShiftInviteErrorDialog(props: ShiftInviteErrorDialogProps) {
  const { modalState, errorMessage } = props;
  const { addUserJourneyEvent } = useShiftInviteContext();

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => {
        addUserJourneyEvent("click", "Backdrop (Shift Invite Error Dialog)");
        modalState.closeModal();
      }}
      aria-labelledby="shift-invite-error-dialog-title"
    >
      <DialogTitle>
        Sorry, this invite can&apos;t be sent at this time
        {isDefined(errorMessage) ? ` due to: ${errorMessage}` : "."} Please try again, or contact
        our support team at {supportPhone} for assistance.
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            addUserJourneyEvent("click", "Close Button (Shift Invite Error Dialog)");
            modalState.closeModal();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
