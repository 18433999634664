import { type UseModalState } from "@clipboard-health/ui-react";
import { NearbyWorkerSearchResult } from "src/containers/Shift/ShiftInvites/Autocomplete/useGetNearbyWorkers";

import { ShiftInviteProvider } from "./Context/shiftInviteContext";
import { ShiftInviteDialog } from "./Dialogs/ShiftInviteDialog";

export interface ShiftInviteDialogWrapperProps {
  workerType: string;
  shiftInfo: {
    start: string;
    end: string;
    shiftType: string;
    timezone?: string;
  };
  modalState: UseModalState;
  initialActiveState?: number;
  initialSelectedWorker?: NearbyWorkerSearchResult;
}

export function ShiftInviteDialogWrapper(props: ShiftInviteDialogWrapperProps): JSX.Element {
  const { workerType } = props;

  return (
    <ShiftInviteProvider agentReq={workerType}>
      <ShiftInviteDialog {...props} />
    </ShiftInviteProvider>
  );
}
